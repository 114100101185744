import axios from 'axios';
import authHeader from './auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const read = async (query_string) => {
    return axios.get(`${API_URL_DEFAULT}approval/?${query_string}`, {
        headers: await authHeader(),
    });
};
const generate = async (query_string) => {
    return axios.get(`${API_URL_DEFAULT}file/?${query_string}`, {
        headers: await authHeader(),
    });
};

const doApproval = async (payload) => {
    return axios.post(`${API_URL_DEFAULT}approval/do-approval/`, payload, { headers: await authHeader() });
};

export default {
    read, generate, doApproval
};
