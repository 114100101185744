const publicKey = process.env.REACT_APP_PUBLIC_KEY;
const authHeader = async () => {
	const applicationToken = JSON.parse(localStorage.getItem('appToken'));
	if (!applicationToken) {
		return {
			'x-public-key': publicKey,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		};
	}
	return {
		'x-public-key': publicKey,
		'x-application-token': `Bearer ${applicationToken.appToken}`,
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	};
};
export default authHeader;
