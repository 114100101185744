import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import PrivateRoute from '../../components/PrivateRoute';
// import Dashboard from '../../views/Dashboard';
import PAGE_404 from '../../pages/presentation/auth/Page404';
import Approval from '../../views/Approval';
import Unauthorized from '../../views/Unauthorized';

const ContentRoutes = () => {
	return (
		<Routes>
			<Route element={<Approval />} path='approval/*' />
			<Route element={<Unauthorized />} path='unauthorized/*' />
			{/* <Route
				exact
				path='/'
				element={
					accessToken ? <Navigate to='/approval' replace /> : <Navigate to='/not-found' replace />
				}
			/> */}
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
