import React from 'react';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';

const Unauthorized = () => {
    return (
        <PageWrapper title='Page 404'>
            <Page>
                <div className='row d-flex align-items-center h-100'>
                    <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
                        <div
                            className='text-primary fw-bold'
                            style={{ fontSize: 'calc(3rem + 3vw)' }}>
                            Unauthorized
                        </div>
                        <div
                            className='text-dark fw-bold'
                            style={{ fontSize: 'calc(1.5rem + 1.5vw)' }}>
                            Access is Denied
                        </div>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};

export default Unauthorized;
