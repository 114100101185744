import React, { useEffect, useState } from 'react';
import { useSearchParams, } from 'react-router-dom';
import IFrame from 'react-iframe';
import Swal from 'sweetalert2';
import showNotification from '../components/extras/showNotification';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';
import Alert from '../components/bootstrap/Alert';
import Spinner from '../components/bootstrap/Spinner';
import Button from '../components/bootstrap/Button';
import Card, {
  CardBody, CardTitle, CardHeader, CardLabel
} from '../components/bootstrap/Card';
import ApprovalModule from '../modules/ApprovalModule';
import AuthModule from '../modules/AuthModule';
import useDarkMode from '../hooks/useDarkMode';
import { capitalize } from '../helpers/helpers'
import Modal, { ModalBody } from '../components/bootstrap/Modal';


const Approval = () => {
  const { darkModeStatus } = useDarkMode();
  const [searchParams,] = useSearchParams();
  const [accessToken, setAccessToken] = useState(null)
  const [existApproval, setExistApproval] = useState(null)
  const [person, setPerson] = useState(null)
  const [dataInfo, setDataInfo] = useState(null)
  const [loadingPage, setLoading] = useState(null)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [contentDocument, setContentDocument] = useState('');
  const [fileExists, setFileExists] = useState(false);
  const [loadingGenerate, setLoadingGenerate] = useState(false);

  const typeApproval = {
    approve: 'approved',
    check: 'checked',
    reject: 'rejected',
  };
  const fetchData = (acc) => {
    setLoading(true);
    const query = `access_code=${acc}`;
    ApprovalModule.read(query)
      .then((response) => {
        const oneDriveID = response?.sign_id ?? response?.onedrive_id
        generate(oneDriveID)
        const getPerson = response.approval_result.find((u) => u.access_code == acc)
        const alreadyApproval = !["waiting"].includes(getPerson.approval_status) ?? false
        if (response.onedrive_id) {
          setFileExists(true)
        }
        setPerson(getPerson)
        setExistApproval(alreadyApproval)
        const gi = Object.keys(response.general_information);
        const assignGI = Array.from(gi.map((ag) => {
          if (ag == 'transaction_code') {
            return null;
          }
          return { value: response.general_information[ag], key: ag }
        }).filter((f) => f))
        const convertArray = [...assignGI]
        setDataInfo(convertArray)
      })
      .catch((err) => {
        window.location.href = '/not-found'
        showNotification('Information', `${err}`, 'danger');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleApproval = (type) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Please check your entries !',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoadingSubmit(true);
        // call module
        const collection = {
          access_code: accessToken,
          approval_type: type,
          result: typeApproval[type],
        };
        ApprovalModule.doApproval(collection)
          .then(() => {
            fetchData(searchParams.get('accessToken'));
          })
          .catch((error) => {
            showNotification('Information!', error, 'danger');
          })
          .finally(() => {
            setLoadingSubmit(false);
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your data is safe :)', 'error');
      }
    });
  };

  const handleReject = () => {
    // eslint-disable-next-line no-async-promise-executor
    const newResponse = new Promise(async (resolve, reject) => {
      try {
        const { value: text } = await Swal.fire({
          input: 'textarea',
          inputLabel: 'Reason',
          inputPlaceholder: 'Input your remarks here...',
          inputAttributes: {
            'aria-label': 'Input your remark here',
          },
          showCancelButton: true,
          // eslint-disable-next-line consistent-return
          inputValidator: (value) => {
            if (!value) {
              return 'You need to write something!';
            }
          },
        });

        if (text) {
          Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!',
          }).then((result) => {
            if (result.isConfirmed) {
              const collection = {
                access_code: accessToken,
                approval_type: 'reject',
                result: typeApproval.reject,
                remark: text,
              };
              ApprovalModule.doApproval(collection)
                .then(() => {
                  fetchData(searchParams.get('accessToken'));
                })
                .catch((error) => {
                  showNotification('Information!', error, 'danger');
                })
                .finally(() => {
                  setLoadingSubmit(false);
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire('Cancelled', 'Your data is safe :)', 'error');
              reject(new Error('Cancelled'));
            }
          });
        }
      } catch (e) {
        reject(new Error({ error: true }));
      }
    });
    return newResponse;
  };

  const generate = (onedrive_id) => {
    setLoadingGenerate(true);

    const query = `onedrive_id=${onedrive_id}`;
    ApprovalModule.generate(query)
      .then((response) => {
        const content = `data:${response.mimetype};base64,${response.file}`;
        setContentDocument(content);
      })
      .catch((error) => {
        showNotification('Information!', error, 'danger');
      })
      .finally(() => {
        setLoadingGenerate(false);
      });
  };
  // eslint-disable-line react/prop-types
  useEffect(() => {

    if (searchParams.get('accessToken') == null || searchParams.get('accessToken') == '') {

      window.location.href = '/unauthorized'
    } else {
      setAccessToken(searchParams.get('accessToken'))
      fetchData(searchParams.get('accessToken'))
      AuthModule.generateToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PageWrapper title='Approval'>
      <Page container='fluid'>
        <Modal
          isOpen={loadingSubmit}
          size='sm'
          isCentered
          setIsOpen={() => { }}
          isStaticBackdrop>
          <ModalBody
            style={{ backgroundColor: '#6c5dd3', color: 'white' }}
            className='text-center'>
            <button className='btn btn-primary' type='button' disabled>
              <span
                className='spinner-grow spinner-grow-sm'
                role='status'
                aria-hidden='true'
              />
              &nbsp;
              <span
                className='spinner-grow spinner-grow-sm'
                role='status'
                aria-hidden='true'
              />
              &nbsp;
              <span
                className='spinner-grow spinner-grow-sm'
                role='status'
                aria-hidden='true'
              />
              &nbsp;
              <span className='sr-only'>Loading...</span>
            </button>
          </ModalBody>
        </Modal>

        {!loadingPage && accessToken && <>
          <div className='row'>
            <div className='col-12'>
              <Alert
                icon='Verified'
                isLight
                color='success'
                rounded={1}
                borderWidth={0}
                className='shadow-3d-primary'>
                <h5>Dear  {person?.name}.  </h5>
                <h6>Please review and let us know your suggestion on attached file</h6>
              </Alert>
            </div>
          </div>
          <div className='row'>
            <div className={!fileExists ? 'col-md-12  col-sm-12' : 'col-md-6  col-sm-12'}>
              <Card stretch>
                <CardHeader borderSize={1} >
                  <CardLabel icon='Info' iconColor='info'>

                    <CardTitle >
                      General Information
                    </CardTitle>
                  </CardLabel>
                </CardHeader>
                <CardBody  >
                  <div className='col-md-12 col-sm-12'>
                    <table className='table table-bordered'>
                      <thead className='table table-modern'>
                        {
                          dataInfo &&
                          dataInfo.map((d, i) => {

                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <tr key={i}>
                                <th>{[capitalize(d.key)]}</th>
                                <th>{[d.value]}</th>
                              </tr>
                            )
                          })
                        }
                      </thead>
                    </table>
                  </div>
                  {
                    (person?.approval_name == 'approve' || person?.approval_name == 'check') && person?.approval_status == 'waiting' && (

                      <div className='col-md-12 col-sm-12 d-flex justify-content-between'>
                        <Button
                          isLight={darkModeStatus}
                          icon='Close'
                          color='danger'
                          size='lg'
                          className='mr-2'
                          onClick={() => handleReject()}>
                          Reject
                        </Button>

                        <Button className='mr-2' isLight={darkModeStatus} icon='CheckCircleOutline' color='success' size='lg' onClick={() => handleApproval(person?.approval_name)}>
                          {
                            person?.approval_name == 'check' ? 'Check' : 'Approve'
                          }
                        </Button>
                      </div>
                    )
                  }
                  {
                    (person?.approval_name == 'approve' || person?.approval_name == 'check') && person?.approval_status != 'waiting' && existApproval && (
                      <div className='col-md-12 col-sm-12 d-flex justify-content-center'>
                        {(person.result == 'approved' || person.result == 'checked') && (
                          <Button isLight={darkModeStatus} icon='CheckCircleOutline' color='success' size='lg' className='mr-2' isDisable>
                            {
                              person?.approval_name == 'check' ? 'Checked' : 'Approved'
                            }
                          </Button>
                        )} {person.result == 'rejected' && (
                          <Button isLight={darkModeStatus} icon='Close' color='danger' size='lg' className='mr-2' isDisable>
                            Rejected
                          </Button>
                        )}
                      </div>

                    )
                  }
                </CardBody>
              </Card>
            </div>
            {fileExists && (
              <div className='col-md-6  col-sm-12'>
                <Card stretch >
                  <CardHeader borderSize={1} >
                    <CardLabel icon='FilePresent' iconColor='info'>

                      <CardTitle >
                        Attached File
                      </CardTitle>
                    </CardLabel>
                  </CardHeader>
                  <CardBody  >
                    {loadingGenerate &&
                      (<Button color='light' isLight={darkModeStatus}>
                        <Spinner isSmall inButton='onlyIcon' />

                      </Button>)}
                    {!loadingGenerate && (<IFrame
                      src={contentDocument}
                      width={`${(window.innerWidth / 3 * 1.4) - 10}px`}
                      height={`${(window.innerHeight / 3 * 2) + 100}px`}
                      id='myId'
                      className='myClassname'
                      display='initial'
                      position='relative'
                    />)}
                  </CardBody>
                </Card>
              </div>)
            }
          </div>
        </>}
      </Page>
    </PageWrapper>
  );
};

export default Approval;
