import ApprovalServices from '../services/approval.service';


const read = (query_string) => {
    return ApprovalServices.read(query_string).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            return Promise.reject(message);
        },
    );
};
const generate = (query_string) => {
    return ApprovalServices.generate(query_string).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            return Promise.reject(message);
        },
    );
};
const doApproval = (query_string) => {
    return ApprovalServices.doApproval(query_string).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            return Promise.reject(message);
        },
    );
};

export default { read, generate, doApproval };
